var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{staticClass:"py-8",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('h1',{staticClass:"text-3xl font-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.resetErrors(); _vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_c('div',{staticClass:"grid lg:grid-cols-12 gap-4 mt-8"},[_c('cv-checkbox',{staticClass:"lg:col-span-12",attrs:{"label":"Realizará o simulado da prova da SBQ.","value":"true"},model:{value:(_vm.form.isExamCandidate),callback:function ($$v) {_vm.$set(_vm.form, "isExamCandidate", $$v)},expression:"form.isExamCandidate"}}),_c('ValidationProvider',{staticClass:"lg:col-span-6",attrs:{"name":"nameFull","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"Nome Completo","invalid-message":_vm.form.errors.nameFull || errors[0]},model:{value:(_vm.form.nameFull),callback:function ($$v) {_vm.$set(_vm.form, "nameFull", $$v)},expression:"form.nameFull"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-4",attrs:{"name":"nameCred","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"Nome da Credencial","invalid-message":_vm.form.errors.nameCred || errors[0]},model:{value:(_vm.form.nameCred),callback:function ($$v) {_vm.$set(_vm.form, "nameCred", $$v)},expression:"form.nameCred"}})]}}],null,true)}),_c('cv-dropdown',{staticClass:"lg:col-span-2",attrs:{"label":"Sexo"},model:{value:(_vm.form.sex),callback:function ($$v) {_vm.$set(_vm.form, "sex", $$v)},expression:"form.sex"}},[_c('cv-dropdown-item',{attrs:{"value":"m"}},[_vm._v(" Masculino ")]),_c('cv-dropdown-item',{attrs:{"value":"f"}},[_vm._v(" Feminino ")])],1),_c('ValidationProvider',{staticClass:"lg:col-span-8",attrs:{"name":"email","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"E-mail","invalid-message":_vm.form.errors.email || errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('cv-text-input',{staticClass:"lg:col-span-4",attrs:{"label":"Especialidade","invalid-message":_vm.form.errors.speciality},model:{value:(_vm.form.speciality),callback:function ($$v) {_vm.$set(_vm.form, "speciality", $$v)},expression:"form.speciality"}}),_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"name":"cpf","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"invalid-message":_vm.form.errors.cpf || errors[0],"label":"CPF/Passaporte"},model:{value:(_vm.form.cpf),callback:function ($$v) {_vm.$set(_vm.form, "cpf", $$v)},expression:"form.cpf"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"name":"rg","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"RG","invalid-message":_vm.form.errors.rg || errors[0]},model:{value:(_vm.form.rg),callback:function ($$v) {_vm.$set(_vm.form, "rg", $$v)},expression:"form.rg"}})]}}],null,true)}),_c('cv-text-input',{staticClass:"lg:col-span-3",attrs:{"label":"CRM","invalid-message":_vm.form.errors.crm},model:{value:(_vm.form.crm),callback:function ($$v) {_vm.$set(_vm.form, "crm", $$v)},expression:"form.crm"}}),_c('cv-text-input',{staticClass:"lg:col-span-3",attrs:{"label":"TEOT","invalid-message":_vm.form.errors.teot},model:{value:(_vm.form.teot),callback:function ($$v) {_vm.$set(_vm.form, "teot", $$v)},expression:"form.teot"}}),_c('ValidationProvider',{staticClass:"lg:col-span-6",attrs:{"tag":"div","name":"cep","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"invalid-message":_vm.form.errors.institution || errors[0],"label":"Instituição *"},model:{value:(_vm.form.institution),callback:function ($$v) {_vm.$set(_vm.form, "institution", $$v)},expression:"form.institution"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-9",attrs:{"tag":"div","name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"invalid-message":_vm.form.errors.address || errors[0],"label":"Endereço *"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})]}}],null,true)}),_c('cv-text-input',{directives:[{name:"mask",rawName:"v-mask",value:('#################################'),expression:"'#################################'"}],staticClass:"lg:col-span-3",attrs:{"label":"Número","invalid-message":_vm.form.errors.number},model:{value:(_vm.form.number),callback:function ($$v) {_vm.$set(_vm.form, "number", $$v)},expression:"form.number"}}),_c('cv-text-input',{staticClass:"lg:col-span-5",attrs:{"label":"Complemento","invalid-message":_vm.form.errors.ref},model:{value:(_vm.form.ref),callback:function ($$v) {_vm.$set(_vm.form, "ref", $$v)},expression:"form.ref"}}),_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","name":"district","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{staticClass:"lg:col-span-3",attrs:{"invalid-message":_vm.form.errors.district || errors[0],"label":"Bairro *"},model:{value:(_vm.form.district),callback:function ($$v) {_vm.$set(_vm.form, "district", $$v)},expression:"form.district"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{staticClass:"lg:col-span-3",attrs:{"invalid-message":_vm.form.errors.city || errors[0],"label":"Cidade *"},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-1",attrs:{"tag":"div","name":"UF","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"invalid-message":_vm.form.errors.uf || errors[0],"label":"UF *"},model:{value:(_vm.form.uf),callback:function ($$v) {_vm.$set(_vm.form, "uf", $$v)},expression:"form.uf"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"invalid-message":_vm.form.errors.country || errors[0],"label":"País"},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","name":"cep","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"invalid-message":_vm.form.errors.cep || errors[0],"label":"CEP/ZIP *"},model:{value:(_vm.form.cep),callback:function ($$v) {_vm.$set(_vm.form, "cep", $$v)},expression:"form.cep"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","name":"mobile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"invalid-message":_vm.form.errors.mobile || errors[0],"label":"Celular *","type":"tel"},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}})]}}],null,true)}),_c('cv-text-input',{staticClass:"lg:col-span-3",attrs:{"invalid-message":_vm.form.errors.phone,"label":"Telefone","type":"tel"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),(!_vm.isVoucher)?_c('cv-text-area',{staticClass:"lg:col-span-12",attrs:{"label":"Notas","rows":"5"},model:{value:(_vm.form.notes),callback:function ($$v) {_vm.$set(_vm.form, "notes", $$v)},expression:"form.notes"}}):_vm._e(),(!_vm.isVoucher)?_c('ValidationProvider',{staticClass:"lg:col-span-8",attrs:{"name":"subscription type","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.subscriptionId || (_vm.subscriptionId && _vm.form.eventSubscriptionTypeId))?_c('EventSubscriptionTypeDropdown',{attrs:{"event-id":_vm.eventId,"invalid-message":_vm.form.errors.eventSubscriptionTypeId || errors[0]},model:{value:(_vm.form.eventSubscriptionTypeId),callback:function ($$v) {_vm.$set(_vm.form, "eventSubscriptionTypeId", $$v)},expression:"form.eventSubscriptionTypeId"}}):_vm._e()]}}],null,true)}):_vm._e(),(!_vm.isVoucher)?_c('div',{staticClass:"lg:col-span-4"},[(_vm.subscriptionId)?_c('div',{staticClass:"flex flex-col"},[_c('label',{staticClass:"bx--label"},[_vm._v("Editar Pagamento")]),_c('router-link',{attrs:{"to":{ name: 'EventSubscriptionOperations', params: { eventId: _vm.eventId, subscriptionId: _vm.subscriptionId }}}},[_c('cv-button',{attrs:{"kind":"secondary"}},[_vm._v(" Clique aqui para Editar "),_c('Ticket16',{staticClass:"bx--btn__icon"})],1)],1)],1):_c('div',[_c('label',{staticClass:"bx--label"},[_vm._v("Pagamento")]),_c('span',{staticClass:"bx--label"},[_vm._v("Você podera editar os dados de pagamento depois de cadastrar a Inscricão")])])]):_vm._e()],1),_c('div',{staticClass:"w-full lg:w-1/4"},[_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}})],1),(_vm.done)?_c('cv-button',{staticClass:"whitespace-no-wrap mt-1",attrs:{"type":"submit"}},[_vm._v(" Enviar ")]):_c('cv-button-skeleton')],1),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }



















































































































































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mask } from 'vue-the-mask'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'

import EventUserDropdown from '@/partials/forms/Events/EventUserDropdown.vue'
import EventSubscriptionTypeDropdown from '@/partials/forms/Events/EventSubscriptionTypeDropdown.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import MoneyInput from '@/partials/forms/components/MoneyInput.vue'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import Ticket16 from '@carbon/icons-vue/es/ticket/16'

@Component({
  directives: { mask },
  components: {
    EventSubscriptionTypeDropdown,
    EventUserDropdown,
    PasswordConfirmField,
    MoneyInput,
    ValidationObserver,
    ValidationProvider,
    NotifyOnFailed,
    Ticket16
  }
})
export default class EventSubscriptionForm extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) title!: string
  @Prop({ type: String }) subscriptionId!: string
  @Prop({ type: Boolean, default: false }) isVoucher!: boolean
  done = true
  form = {
    paid: 0,
    eventSubscriptionTypeId: '',
    eventId: '',
    eventUserId: '',
    nameFull: '',
    nameCred: '',
    sex: 'm',
    email: '',
    cpf: '',
    rg: '',
    crm: '',
    teot: '',
    speciality: '',
    address: '',
    cep: '',
    uf: '',
    number: '',
    ref: '',
    district: '',
    city: '',
    country: 'Brasil',
    phone: '',
    mobile: '',
    institution: '',
    notes: '',
    isExamCandidate: '',
    confirm: '',
    errors: {
      paid: '',
      eventSubscriptionTypeId: '',
      eventId: '',
      eventUserId: '',
      nameFull: '',
      nameCred: '',
      sex: '',
      email: '',
      cpf: '',
      rg: '',
      crm: '',
      teot: '',
      speciality: '',
      address: '',
      cep: '',
      uf: '',
      number: '',
      ref: '',
      district: '',
      city: '',
      country: '',
      phone: '',
      mobile: '',
      institution: '',
      notes: '',
      isExamCandidate: '',
      confirm: ''
    }
  }

  created () {
    this.done = false
    if (process.env.NODE_ENV === 'development') {
      Object.assign(this.form, {
        paid: 0.0,
        eventSubscriptionTypeId: '',
        eventId: '',
        eventUserId: '',
        nameFull: 'Nome Completo',
        nameCred: 'Nome no Crachá',
        sex: 'f',
        email: 'inscrito@indicativa.com.br',
        cpf: '42131342412',
        speciality: 'Ortopedia e Traumatologia',
        rg: '1111',
        crm: '1111',
        teot: '1111',
        address: 'Endereço ABC',
        district: 'Bairro',
        city: 'Cidade',
        cep: '11111111',
        uf: 'DF',
        country: 'Brasil',
        phone: '+551234765490',
        mobile: '+5511998899880',
        institution: 'Indicativa',
        notes: 'Observações'
      })
    }
    if (this.formType === 'edit') {
      axios.get(`/event/${this.eventId}/subscription/${this.subscriptionId}`)
        .then(response => {
          const responseData = camelCaseKeys(response.data.data, { deep: true })
          Object.assign(this.form, responseData)
        })
        .finally(() => { this.done = true })
    } else {
      this.done = true
    }
    this.form.eventId = this.eventId
  }

  submit () {
    const requestVerb = this.formType === 'edit' ? 'put' : 'post'
    let requestUrl = `/event/${this.eventId}/subscription/`
    if (this.formType === 'edit') {
      requestUrl += this.subscriptionId
    }
    this.done = false
    axios[requestVerb](requestUrl, snakeCaseKeys(this.form))
      .then(() => {
        if (this.isVoucher) {
          this.$emit('submitted')
          return
        }

        const flashMessage: flashMessage = {
          message: this.formType === 'edit' ? 'Inscrição editada com sucesso.' : 'Inscrição cadastrada com sucesso.',
          type: 'success'
        }
        this.$router.push({ name: 'EventSubscriptions', params: { id: this.eventId } }, () => this.setFlashMessage(flashMessage))
      })
      .finally(() => { this.done = true })
  }

  @Watch('form.eventUserId')
  onEventUserIdChange () {
    if (this.done) {
      axios.get(`/event/${this.eventId}/users/${this.form.eventUserId}/show`)
        .then(response => {
          const responseData = camelCaseKeys(response.data.data, { deep: true })
          let profile: Record<string, string> = {}

          if (responseData.user.profile) {
            responseData.user.profile.forEach((item: any) => {
              if (item.metaKey) {
                profile[item.metaKey] = item.metaValue
              }
            })
          }

          profile = camelCaseKeys(profile, { deep: true })

          Object.assign(this.form, {
            email: responseData.user.email,
            nameFull: responseData.user.name,
            sex: responseData.user.sex,
            cpf: profile.cpf ? profile.cpf : this.form.cpf,
            speciality: profile.speciality ? profile.speciality : this.form.speciality,
            rg: profile.rg ? profile.rg : this.form.rg,
            crm: profile.crm ? profile.crm : this.form.crm,
            teot: profile.teotNumber ? profile.teotNumber : this.form.teot,
            uf: profile.uf ? profile.uf : this.form.uf,
            phone: profile.phone ? profile.phone : this.form.phone,
            mobile: profile.mobile ? profile.mobile : this.form.mobile
          })
        })
    }
  }

  @Watch('form.eventSubscriptionTypeId')
  onSubscriptionTypeChange (newValue: string) {
    if (this.formType === 'create') {
      axios.get(`event/${this.eventId}/subscription/type/${newValue}`)
        .then(response => {
          this.form.paid = response.data.data.price
        })
    }
  }

  resetErrors () {
    this.form.errors = {
      paid: '',
      eventSubscriptionTypeId: '',
      eventId: '',
      eventUserId: '',
      nameFull: '',
      nameCred: '',
      sex: '',
      email: '',
      cpf: '',
      rg: '',
      crm: '',
      teot: '',
      speciality: '',
      address: '',
      cep: '',
      uf: '',
      number: '',
      ref: '',
      district: '',
      city: '',
      country: '',
      phone: '',
      mobile: '',
      institution: '',
      notes: '',
      isExamCandidate: '',
      confirm: ''
    }
  }

  get formType () {
    return this.subscriptionId ? 'edit' : 'create'
  }
}
